import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class Category extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <div data-target={`cat_${this.data.id}`} className={`categories-item ${this.props.Active?'active':''}`} onClick={ () => this.props.handleCategory(this.data.id)} style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}} >
                {
                    this.props.Language == "en" ? this.data['name_eng']
                    : this.props.Language == "krd" ? this.data.name_krd
                    : this.data.name_ar
                }
            </div>
        );
    }
}

export default Category;
import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import MenuCategory from './MenuCategory';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import CategoryItemsDisplay from './CategoryItemsDisplay';

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        subCategories: [],
        selectedSubCategories:0,
        items: [],
        loadCat: false,
        load: false,
        listview: "two",
        showLangSelect: false,
        categoriesIsFixed: false,
        isManualScrolling: false
    }

    async componentDidMount(){

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/swisslanddavos");
        this.setState({ categories: data }, ()=>{
            this.getCategoryName(-1);
            this.setState({loadCat: true});
        });

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/swisslanddavos");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }

        this.layoutDirection();

        window.addEventListener('scroll', (e) => {             
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;            
            if(scrollPosition > 80) {
                this.setState({ categoriesIsFixed: true });
            } else {
                this.setState({ categoriesIsFixed: false });
            }
        });

        this.setState({ categoriesIsFixed: false });

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        
        if(id !== -1) {

            const selectedCategory = this.state.categories.find(cat => cat.id == id);
            if (selectedCategory) {
                const selectedSubCategory = selectedCategory.subCategories[0];
                this.setState({
                    selectCategory: selectedCategory,
                    subCategories: selectedCategory.subCategories,
                    selectedSubCategories: selectedSubCategory
                });
            } else {
                const selectedSubCategory = this.state.selectCategory.subCategories.find(subCat => subCat.id == id);
                this.setState({
                    selectedSubCategories: selectedSubCategory
                });
            }
        }
        else
            this.setState({ selectCategory: this.state.categories[0], subCategories: this.state.categories[0].subCategories, selectedSubCategories: this.state.categories[0].subCategories[0] });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async (catId) => {

        this.setState({ load: false });
        await this.getCategoryName(catId);
        this.setState({ load: true });
        
    };


    handleSubCategory = async (catId) => {

        console.log(catId);
        
        this.setState({ isManualScrolling: true }, async () => {
            const element = document.getElementById('cat_' + catId);
            
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
    
            await this.getCategoryName(catId);
        
            setTimeout(() => {
                this.setState({ isManualScrolling: false });
            }, 1000); 
        });
    };


    handleListview = style => {
        this.setState({listview: style});
    }

    handleShowLangSelect = e => {
        this.setState({showLangSelect: e});
    }

   
    render() {

        
        
        const categoryLinks = document.querySelectorAll('.categories-item');
        const categorySections = document.querySelectorAll('.category-section');

        function updateActiveCategory(activeId)  {
                    
            let activeLink = null;
        
            categoryLinks.forEach(link => {
                if (link.dataset.target === activeId) {
                    link.classList.add('active');
                    activeLink = link;
                } else {
                    link.classList.remove('active');
                }
            });
        
            if (activeLink) {
                activeLink.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            }
        };
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.state.isManualScrolling) {
                    updateActiveCategory(entry.target.id);
                }
            });
        }, { threshold: 0.5 });

        categorySections.forEach(section => {            
            observer.observe(section);
        });

        const isLtr = (this.props.Language == "en");


        return (
            <div className='menu-items'>
                <div className="head">


                <Link className="logo" to={"/home"}>
                    <img src={this.settings.logo} alt="" />
                </Link>
                    
                    <div className="language">
                        <div 
                                className='language-active' 
                                style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}
                                onClick={ () => this.handleShowLangSelect(true) }>
                                    <i className="fa-solid fa-globe"></i>
                                    {
                                        this.props.Language == "en"? 'English'
                                        : this.props.Language == "krd"? 'کوردی'
                                        : 'العربیة'
                                    }
                                    <i className="fa-solid fa-angle-down"></i>
                            
                        </div>
                    </div>

                </div>


                <div 
                className='menu-categories'
                style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}} >
                    {this.state.loadCat ? (
                        <>
                           {this.state.categories.map((cat) => (
                                <MenuCategory
                                    key={cat.id}
                                    Data={cat}
                                    Language={this.props.Language}
                                    handleCategory={this.handleCategory}
                                    Active={this.state.selectCategory.id === cat.id}
                                />
                            ))}
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>


                <div 
                className={`categories ${this.state.categoriesIsFixed? 'categories-bg': '' }`}
                style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}} >
                    {this.state.loadCat ? (
                        <>
                           {this.state.subCategories.map((cat) => (
                                <Category
                                    key={cat.id}
                                    Data={cat}
                                    Language={this.props.Language}
                                    handleCategory={this.handleSubCategory}
                                    Active={this.state.selectedSubCategories.id === cat.id}
                                />
                            ))}
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>




                {/* <div className='categories'>

                    {
                        this.state.loadCat ?
                        <> 
                            { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategory} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                        </>
                        : <Loader></Loader>
                    }
                    
                </div> */}

                <div className='items' id='itemContainer'>


                

                                                    {
                                                        this.state.load ?
                                                            <PhotoProvider maskOpacity={0.7} overlayRender={ ({ images, index }) => {
                                                                
                                                                if(!images[index]){
                                                                    return null;
                                                                }
                                                                const data = images[index].overlay;                                                                
                                                                
                                                                return (
                                                                    <div className={`item-photo-view`} style={ {'direction': isLtr? "ltr" : "rtl"} }>
                                                                        <div>
                                                                            <h5 style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}>
                                                                                {
                                                                                    this.props.Language == "en" ? data.name_eng
                                                                                    : this.props.Language == "krd" ? data.name_krd
                                                                                    : data.name_ar
                                                                                }          
                                                                            </h5>
                                                                        </div>

                                                                        <div className='price'>
                                                                            <span> {data.price}</span> <span className='currency'>{(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'}</span> 
                                                                        </div>
                                                                        
                                                                        <p>
                                                                            {
                                                                                this.props.Language == "en" ? data.desc_eng
                                                                                : this.props.Language == "krd" ? data.desc_krd
                                                                                : data.desc_ar
                                                                            } 
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }} >
                                                                <CategoryItemsDisplay categories={this.state.subCategories} items={this.state.items} language = {this.props.Language} />
                                                            </PhotoProvider>
                                                        : <Loader></Loader>
                                                    }
                </div>

                <div 
                        className={`language-wrapper ${this.state.showLangSelect? '': 'd-none'}`} 
                        onClick={ () => this.handleShowLangSelect(false)} >
                    <div className={`language-select`}>
                        <div className='language-select-head'><span>Language</span> <i className="fa-solid fa-angle-up"></i></div>
                        <ul>
                            <li className={`${this.props.Language === "krd"? 'language-select-active': ''}`} onClick={() => this.handleChange('krd')}>کوردی</li>
                            <li className={`${this.props.Language === "ar"? 'language-select-active': ''}`} onClick={() => this.handleChange('ar')}>العربیة</li>
                            <li className={`${this.props.Language === "en"? 'language-select-active': ''}`} style={{'textAlign': 'left'}} onClick={() => this.handleChange('en')}>English</li>
                        </ul>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default Menu;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class MenuCategory extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <div className={`menu-categories-item ${this.props.Active?'active':''}`} onClick={ () => this.props.handleCategory(this.data.id)} >
                <div className='icon'>
                    <CatLoading Img={this.data.logo} />
                </div>
                <span style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}>
                    {
                        this.props.Language == "en" ? this.data['name_eng']
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    }
                </span>
            </div>
        );
    }
}

export default MenuCategory;
import React from 'react';
import Two from './list/two';

const CategoryItemsDisplay = ({ categories = [], items = [], language }) => {
  return (
    <>
      {categories.map(category => (
        <div key={category.id} id={`cat_${category.id}`} className='category-section' >
          <h5 className="category-name" style={ language == "en"? {'fontFamily':'Yellowtail'}: {}} >
            {
                language == "en" ? category.name_eng
                : language == "krd" ? category.name_krd
                : category.name_ar
            }
        </h5>
          {items[category.id] && items[category.id].length > 0 ? (

            <div className='style-two'>
                { items[category.id].map(item => <Two key={item.id}  Data = {item} Language = {language} ></Two> ) }
            </div>
          ) : ''
          }
        </div>
      ))}
    </>
  );
};

export default CategoryItemsDisplay;